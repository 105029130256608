<template>
  <v-row class="ma-0 mb-15">
    <div class="card card-chart hidden relative border-none">
      <v-tabs v-model="tab" class="bb-gray">
        <v-tab :ripple="false" class="px-0 mx-4 fz-14 text-none fw-600 hover-none" v-for="(tab, index) in tabs" :key="index">{{ tab.name }}</v-tab>
      </v-tabs>
      <div class="card-content py-8">
<!--        <svg v-show="tab !== 2" class="chart-donut chart-income"></svg>-->
        <donut
          ref="chartIncome"
          v-show="tab !== 2"
          :colors.sync="colors"
          chartName="chart-income"
          :data="selectedMonth.incomeCategories"
          :tooltip.sync="tooltip"
          tooltiptype="income"
          value="value.amount"
          name="category.name"
        />
        <donut ref="chartExpenses" v-show="tab !== 1" :colors.sync="colors" chartName="chart-expenses" :data="selectedMonth.expenseCategories" :tooltip.sync="tooltip" tooltiptype="expenses" />
<!--        <svg v-show="tab !== 1" class="chart-donut chart-expenses"></svg>-->
        <div v-if="tab" class="card-chips flex-wrap">
          <v-chip
            label
            color="white"
            v-for="(item, index) in selectedMonth[tab === 1 ? 'incomeCategories' : 'expenseCategories']" :key="item.category.id"
            class="text-black fz-14 mr-3 mb-2"
          ><span  :style="{'background-color': colors ? colors(index) : null}"></span>{{ item.category.name + ": "}}{{ item.value.symbol !== '₽' ? item.value.symbol : ''}}{{item.value.amount | numberFormat }}{{ item.value.symbol === '₽' ? item.value.symbol : ''}}
          </v-chip>
        </div>
        <div v-if="selectedMonth.totalIncomeUsd && tab !== 2" class="card-total card-total-income font-weight-medium">
          <p class="ma-0 mb-n1 fz-14 text-black text-center" v-if="!tab">Income</p>
          ${{ tooltip.income ? tooltip.income.value : selectedMonth.totalIncomeUsd.amount }}
          <p class="ma-0 mt-n1 fz-14 text-black text-center" v-if="tooltip.income">{{ tooltip.income.name }}</p>
        </div>
        <div v-if="selectedMonth.expenseCategories && tab !== 1"
             class="card-total card-total-expenses font-weight-medium"
             :style="{'left': tab ? '170px' : '450px'}"
        >
          <p class="ma-0 mb-n1 fz-14 text-black text-center" v-if="!tab">Expenses</p>
          ${{ tooltip.expenses ? tooltip.expenses.value : selectedMonth.totalExpensesUsd.amount }}
          <p class="ma-0 mt-n1 fz-14 text-black text-center" v-if="tooltip.expenses">{{ tooltip.expenses.name }}</p>
        </div>
      </div>
    </div>
  </v-row>
</template>

<script>
import numberFormat from '@/helpers/numberFormat'
import donut from '@/components/App/charts/Donut'

export default {
  name: 'ChartDonut',
  components: { donut },
  props: ['selectedMonth', 'queryOptions'],
  filters: {
    numberFormat
  },
  data() {
    return {
      tab: 0,
      tabs: [
        {
          name: 'All'
        },
        {
          name: 'Income'
        },
        {
          name: 'Expenses'
        }
      ],
      colors: null,
      tooltip: {
        income: null,
        expenses: null
      }
    }
  },
  methods: {
    createChartIcome () {
      const self = this
      setTimeout(function () {
        self.$refs.chartIncome.createChart()
      })
    },
    createChartExpenses () {
      const self = this
      setTimeout(function () {
        self.$refs.chartExpenses.createChart()
      })
    }
  },
  watch: {
    selectedMonth() {
      this.createChartIcome()
      this.createChartExpenses()
    },
    tab() {
      this.queryOptions.type = this.tab === 1 ? 'INCOME' : this.tab === 2 ? 'EXPENSE' : null
    }
  },
  mounted() {
    if (this.selectedMonth.monthName) {
      this.createChartIcome()
      this.createChartExpenses()
    }
  }
}
</script>

<style lang="scss">
.card {
  &-chart {
    background: #fff;
    border: 1px solid #EEF2F7;
    box-sizing: border-box;
    border-radius: 16px;
    width: 100%;
  }
  &-content {
    display: flex;
    align-items: center;
    padding-left: 70px;
    padding-right: 80px;
    position: relative;
  }
  &-total {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: 500;
    &-income {
      left: 170px;
    }
  }
  .v-tabs-bar {
    background: unset !important;
  }
  .v-tab {
    min-width: 30px;
  }
  .v-tab--active {
    color: #010820 !important;
  }
  .v-chip__content span {
    width: 12px;
    height: 12px;
    border-radius: 4px;
    margin-right: 10px;
  }
}
</style>
