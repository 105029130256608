<template>
  <v-row class="ma-0 finance-table">
    <v-col class="pa-0">
      <v-row class="ma-0">
        <v-col class="pa-0">
          <v-data-table
          class="orders-table table-vertical-line"
          :headers="headers"
          :items="operations"
          hide-default-footer
          @click:row="showOperation"
          :header-props="{ sortIcon: 'mdi-menu-down' }"
          :server-items-length="tableOptions.totalItems"
          :options.sync="options"
        >
            <!--          @click:row="getOrder"  -->
            <template v-slot:header.number="">
              <v-text-field
                @click.stop
                prepend-inner-icon="$search"
                placeholder="Number"
                v-model="queryOptions.searchNumber"
                outlined
                clearable
                class="table-search d-inline-block ma-0 input-clear-small"
                dense
              ></v-text-field>
            </template>
            <template v-slot:header.account>
              <v-select
                v-model="queryOptions.selectedAccountIds"
                :items="accounts"
                item-text="name"
                item-value="id"
                single-line
                multiple
                outlined
                class="rounded-lg fz-14 font-weight-medium inline-input border-none input-clear-small select-dash status-select"
                dense
                clearable
                item-color="#010820"
                hide-details
                hide-spin-buttons
                :menu-props="{ bottom: true, offsetY: true }"
              >
                <template v-slot:prepend-inner>
                  <span style="padding-top: 5px;">Account</span>
                  <v-icon style="padding-top: 2px;" size="20" v-text="'mdi-chevron-down'"></v-icon>
                  <v-icon
                    v-if="queryOptions.selectedAccountIds.length"
                    style="padding-top: 6px; margin-left: 5px;"
                    class="pointer" small
                    @click="queryOptions.selectedAccountIds = []"
                  >
                    $close
                  </v-icon>
                </template>
                <template v-slot:append-outer>
                </template>
                <template v-slot:selection>
                </template>
              </v-select>
            </template>
            <template v-slot:header.category>
              <v-select
                v-model="queryOptions.selectedCategoryIds"
                :items="categories"
                item-text="name"
                item-value="id"
                single-line
                multiple
                outlined
                class="rounded-lg fz-14 font-weight-medium inline-input border-none input-clear-small select-dash status-select"
                dense
                clearable
                item-color="#010820"
                hide-details
                hide-spin-buttons
                :menu-props="{ bottom: true, offsetY: true }"
              >
                <template v-slot:prepend-inner>
                  <span style="padding-top: 5px;">Category</span>
                  <v-icon style="padding-top: 2px;" size="20" v-text="'mdi-chevron-down'"></v-icon>
                  <v-icon
                    v-if="queryOptions.selectedCategoryIds.length"
                    style="padding-top: 6px; margin-left: 5px;"
                    class="pointer" small
                    @click="queryOptions.selectedCategoryIds = []"
                  >
                    $close
                  </v-icon>
                </template>
                <template v-slot:append-outer>
                </template>
                <template v-slot:selection>
                </template>
              </v-select>
            </template>
            <template v-slot:item.number="{ item }">
            {{ item.id }}
          </template>
          <template v-slot:item.account="{ item }">
            {{ item.account.name }}
          </template>
          <template v-slot:item.date="{ item }">
            {{ formatDate(item.date) }}
          </template>
          <template v-slot:item.category="{ item }">
            {{ item.category.name }}
          </template>
          <template v-slot:item.money="{ item }">
            <span :class="{'red-text': +item.moneyDelta.amount < 0, 'green-text': +item.moneyDelta.amount > 0 }">
                {{ item.moneyDelta.symbol !== '₽' ? item.moneyDelta.symbol : '' }}{{ +item.moneyDelta.amount > 0 ? '+' : null }}{{ item.moneyDelta.amount | numberFormat }}{{ item.moneyDelta.symbol === '₽' ? item.moneyDelta.symbol : ''  }}
            </span>
            <!-- {{ item.moneyIn ? item.moneyIn.amount : null | numberFormat }}
            {{ item.moneyIn && item.moneyIn.symbol }} -->
          </template>
          <template v-slot:item.overallBalance="{ item }">
            {{ item.overallBalance && (item.overallBalance.symbol !== '₽') ? item.overallBalance.symbol : ''  }}{{ item.overallBalance ? item.overallBalance.amount : null | numberFormat }}{{ item.overallBalance && (item.overallBalance.symbol === '₽') ? item.overallBalance.symbol : ''  }}
          </template>
          <template v-slot:item.attachments="{ item }">
            <v-row class="ma-0 flex-nowrap align-center px-2" style="height: 100%" @click.stop>
              <v-tooltip
                top
                v-if="item.attachments && item.attachments.includes(item.attachments.find(el => el.type === 'CONTRACT'))"
                content-class="no-triangle">
                <template v-slot:activator="{ on, attrs }">
                  <a
                    v-bind="attrs"
                    v-on="on"
                    target="_blank"
                    :href="item.attachments.find(el => el.type === 'CONTRACT').url">
                    <v-img
                      height="30"
                      width="30"
                      class="pointer d-inline-block"
                      src="@/assets/img/word.png"></v-img>
                  </a>
                </template>
                <v-col class="pa-0">
                  <span class="d-block fz-13 fw-600 white--text">{{ item.attachments.find(el => el.type === 'CONTRACT').name }}</span>
                </v-col>
              </v-tooltip>
              <v-tooltip
                top
                v-else
                content-class="no-triangle">
                <template v-slot:activator="{ on, attrs }">
              <v-img
                    v-bind="attrs"
                    v-on="on"
                  height="30"
                  width="30"
                  max-height="30"
                  max-width="30"
                  class="pointer d-inline-block"
                  src="@/assets/img/word-empty.png"></v-img>
                </template>
                <v-col class="pa-0">
                  <span class="d-block fz-13 fw-600 white--text">No contract</span>
                </v-col>
              </v-tooltip>
              <v-tooltip
                top
                v-if="item.attachments && item.attachments.includes(item.attachments.find(el => el.type === 'INVOICE'))"
                content-class="no-triangle">
                <template v-slot:activator="{ on, attrs }">
                  <a
                    v-bind="attrs"
                    v-on="on"
                    target="_blank"
                    :href="item.attachments.find(el => el.type === 'INVOICE').url">
                    <v-img
                      height="30"
                      width="30"
                      class="pointer d-inline-block"
                      src="@/assets/img/pdf.png"></v-img>
                  </a>
                </template>
                <v-col class="pa-0">
                  <span class="d-block fz-13 fw-600 white--text">{{ item.attachments.find(el => el.type === 'INVOICE').name }}</span>
                </v-col>
              </v-tooltip>
              <v-tooltip
                top
                v-else
                content-class="no-triangle">
                <template v-slot:activator="{ on, attrs }">
              <v-img
                    v-bind="attrs"
                    v-on="on"
                  height="30"
                  width="30"
                  max-height="30"
                  max-width="30"
                  class="pointer d-inline-block"
                  src="@/assets/img/pdf-empty.png"></v-img>
                </template>
                <v-col class="pa-0">
                  <span class="d-block fz-13 fw-600 white--text">No invoice</span>
                </v-col>
              </v-tooltip>
              <v-tooltip
                top
                v-if="item.attachments && item.attachments.includes(item.attachments.find(el => el.type === 'COMPLETION'))"
                content-class="no-triangle">
                <template v-slot:activator="{ on, attrs }">
                  <a
                    v-bind="attrs"
                    v-on="on"
                    target="_blank"
                    :href="item.attachments.find(el => el.type === 'COMPLETION').url">
                    <v-img
                      height="30"
                      width="30"
                      class="pointer d-inline-block"
                      src="@/assets/img/excel.png"></v-img>
                  </a>
                </template>
                <v-col class="pa-0">
                  <span class="d-block fz-13 fw-600 white--text">{{ item.attachments.find(el => el.type === 'COMPLETION').name }}</span>
                </v-col>
              </v-tooltip>
              <v-tooltip
                top
                v-else
                content-class="no-triangle">
                <template v-slot:activator="{ on, attrs }">
              <v-img
                    v-bind="attrs"
                    v-on="on"
                  height="30"
                  width="30"
                  max-height="30"
                  max-width="30"
                  class="pointer d-inline-block"
                  src="@/assets/img/excel-empty.png"></v-img>
                </template>
                <v-col class="pa-0">
                  <span class="d-block fz-13 fw-600 white--text">No completion</span>
                </v-col>
              </v-tooltip>
            </v-row>
          </template>
          <template v-slot:item.remove="{ item }">
          <v-dialog
              transition="dialog-bottom-transition"
              max-width="370"
              content-class="rounded-xl"
              overlay-color="rgba(9, 24, 73, 0.2)"
              overlay-opacity="1"
            >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="0"
                x-small
                icon
                fab
              >
                <v-icon size="20"  class="stroke-gray">$delete</v-icon>
              </v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card class="pa-7">
                <v-card-text class="py-0">
                  <div class="text-black font-weight-medium fz-18 text-center">Do you really want to delete operation #{{ item.id }} ?</div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-space-between pa-0">
                  <v-btn
                    class="rounded-lg text-none fz-16 fw-600"
                    outlined
                    width="150"
                    height="40"
                    @click="dialog.value = false"
                  >Return</v-btn>
                  <v-btn
                    elevation="0"
                    color="#FF0000"
                    width="150"
                    height="40"
                    class="rounded-lg white--text text-none fz-16 fw-600"
                    @click="deleteOperation(item); dialog.value = false"
                  ><!--                      @click="deleteOrder(item)"-->
                    <v-icon left class="stroke-white">
                      $delete
                    </v-icon>
                    Delete</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </template>
          <template v-slot:body.append>
            <tr class="table-footer">
              <td colspan="5"><span class="text-black fw-600 fz-13">Total</span></td>
              <td>
                <span class="text-black fw-600 fz-13">
                  {{ tableOptions.totalMoneyIn && (tableOptions.totalMoneyIn.symbol !== '₽') ? tableOptions.totalMoneyIn.symbol : ''}}{{ tableOptions.totalMoneyIn ? tableOptions.totalMoneyIn.amount : null | numberFormat }}{{ tableOptions.totalMoneyIn && (tableOptions.totalMoneyIn.symbol === '₽') ? tableOptions.totalMoneyIn.symbol : ''}}
                </span>
              </td>
              <td>
                <span class="text-black fw-600 fz-13">
                  {{ tableOptions.totalMoneyOut && (tableOptions.totalMoneyOut.symbol !== '₽') ? tableOptions.totalMoneyOut.symbol : ''}}{{ tableOptions.totalMoneyOut ? tableOptions.totalMoneyOut.amount : null | numberFormat }}{{ tableOptions.totalMoneyOut && (tableOptions.totalMoneyOut.symbol === '₽') ? tableOptions.totalMoneyOut.symbol : ''}}
                </span>
              </td>
              <td colspan="3">
                <span class="text-black fw-600 fz-13">
                  {{ tableOptions.totalBalance && (tableOptions.totalBalance.symbol !== '₽') ? tableOptions.totalBalance.symbol : ''}}{{ tableOptions.totalBalance ? tableOptions.totalBalance.amount : null | numberFormat }}{{ tableOptions.totalBalance && (tableOptions.totalBalance.symbol === '₽') ? tableOptions.totalBalance.symbol : ''}}
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
          <v-btn
            height="50"
            text
            tile
            color="#7552CC"
            @click="addOperation"
            class="text-none px-3 fz-14">
            <b class="fz-16 fw-600 mr-1"> + </b>Add new item
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-btn
          height="40"
          class="my-auto pl-2 mr-1 rounded-lg text-none fz-14 fw-600"
          outlined
          :disabled="queryOptions.page === 1"
          @click="queryOptions.page--"
          color="primary">
          <v-icon>mdi-chevron-left</v-icon>
          Previous
        </v-btn>
        <v-pagination
          v-model="queryOptions.page"
          class="my-4 pagination text-black fw-600"
          :length="tableOptions.totalPages"
          :total-visible="7"
          previous-aria-label="Previous"
        >
        </v-pagination>
        <v-btn
          height="40"
          class="my-auto ml-1 pr-2 rounded-lg text-none fz-14 fw-600"
          outlined
          :disabled="tableOptions.totalPages === queryOptions.page"
          @click="queryOptions.page++"
          color="primary">
          Next
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-row>
    </v-col>
    <v-dialog
          v-model="financeOperationShow"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          class="z-index-400"
          overlay-color="rgba(9, 24, 73, 0.2)"
          overlay-opacity="1"
        >
      <finance-operation
        :financeOperationShow.sync="financeOperationShow"
        ref="financeOperation"
        :accounts="accounts"
        :operation="operation"
        :readOnly.sync="readOnly"
        @addSnack="addSnack"
        @updateTable="updateTable"
        @resetOperation="resetOperation"
        :categories="categories"/>
    </v-dialog>
    <v-snackbars
        :messages.sync="messages"
        color="red"
        timeout="2000"
        multi-line
        max-width="350"
        left
        rounded="lg"
        light
        elevation="4"
        content-class="snackbar"
      >
      <template v-slot:default="{ message }">
        <strong>{{ message.title }}</strong>
        <p>{{ message.text }}</p>
        <v-row v-if="message.title === 'Error'" class="ma-0 justify-end">
          <v-btn
            class="rounded-lg fz-12 fw-600 text-none border-none"
            outlined
            height="26"
            elevation="0"
            @click="doCopy('Error', message.text)"
          >Copy</v-btn>
        </v-row>
      </template>
    </v-snackbars>
  </v-row>
</template>

<script>
import numberFormat from '@/helpers/numberFormat'
import { Locale } from 'v-calendar'
import FinanceOperation from '@/components/App/Finance/FinanceOperation'
import VSnackbars from 'v-snackbars'
import gql from 'graphql-tag'
const locale = new Locale()

export default {
  name: 'FinanceTable',
  components: {
    FinanceOperation,
    'v-snackbars': VSnackbars
  },
  props: ['headers', 'operations', 'tableOptions', 'categories', 'accounts', 'queryOptions'],
  filters: {
    numberFormat
  },
  data() {
    return {
      messages: [],
      readOnly: true,
      options: {
        itemsPerPage: 999,
        sortBy: ['number'],
        sortDesc: [true],
        page: 1,
        multiSort: false,
        mustSort: false,
        groupBy: [],
        groupDesc: []
      },
      financeOperationShow: false,
      operation: {}
    }
  },
  methods: {
    formatDate (date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    sortTable () {
      let title = ''
      if (this.options.sortBy.length) {
        switch (this.options.sortBy[0]) {
          case 'number': title = 'NUMBER'
            break
          case 'account': title = 'ACCOUNT'
            break
          case 'date': title = 'DATE'
            break
          case 'description': title = 'DESCRIPTION'
            break
          case 'category': title = 'CATEGORY'
            break
          case 'money': title = 'AMOUNT'
            break
          case 'overallBalance': title = 'OVERALL_BALANCE'
            break
        }
        if (this.options.sortDesc[0] === true) title += '_DESC'
        else title += '_ASC'
        this.queryOptions.orderBy = title
      }
    },
    addOperation () {
      if (this.operation.id) {
        this.resetOperation()
        this.$refs.financeOperation.resetValidation()
        this.readOnly = false
      } else if (!this.operation.category) {
        this.resetOperation()
        this.readOnly = false
      }
      this.financeOperationShow = true
    },
    resetOperation() {
      this.operation = {
        amount: {},
        category: {
          name: ''
        },
        type: null
      }
    },
    showOperation (item) {
      this.financeOperationShow = true
      this.readOnly = true
      this.operation = JSON.parse(JSON.stringify(item))
      this.operation.date = this.operation.date ? locale.format(this.operation.date, 'DD.MM.YYYY') : ''
      const vm = this
      setTimeout(function () {
        vm.$refs.financeOperation.setOrder()
        vm.$refs.financeOperation.setWorker()
      })
    },
    updateTable () {
      this.$emit('getFilteredOperations')
    },
    async deleteOperation (item) {
      await this.$apollo.mutate({
        mutation: gql`mutation DeleteOperation ($operationId: ID! ) {
          finance {
            deleteOperation(operationId: $operationId)
          }
        }`,
        variables: {
          operationId: item.id
        },
        fetchPolicy: 'no-cache'
      }).then(() => {
        this.addSnack('item.id', 'Success', item.id + ' was deleted with success')
        this.updateTable()
      }).catch((err) => {
        this.addSnack(item.id, 'Error', err)
        console.log(err)
      })
    },
    addSnack (item, result, text) {
      this.messages.push({ title: result, text: text })
    },
    doCopy (action, value) {
      const vm = this
      this.$copyText(value).then(function () {
        vm.addSnack(value, 'Success', action + ' text copied')
      }, function (e) {
        console.log(e)
      })
    }
  },
  watch: {
    options: {
      handler () {
        this.sortTable()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.finance-table {
  .table-vertical-line tbody tr.table-footer {
    background-color: #EEF2F7;
    td {
      border-right: 1px solid white !important;
    }
  }
}
.no-triangle:before {
  content: none !important;
}

.status-select .v-input__append-inner, .status-select .v-select__slot {
  display: none;
}
</style>
