<template>
  <v-row class="ma-0 mb-15">
    <div class="card card-minimize relative hidden">
      <v-tabs v-model="tab" class="bb-gray">
        <v-tab :ripple="false" class="px-0 mx-4 fz-14 text-none fw-600 hover-none" v-for="(tab, index) in tabs" :key="index">{{ tab.name }}</v-tab>
      </v-tabs>
      <div class="linear-date-picker d-flex">
        <v-btn-toggle
          class="rounded-lg height-30 box-shadow interval black--text hidden"
          style="padding-left: 2px; padding-right: 2px"
          :class="{'mr-3': $vuetify.breakpoint.smAndUp}"
          background-color="#F2F4F5"
          v-model="interval"
          borderless
          dense>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            @click="getPrevious"
            height="26">
            Previous Month
          </v-btn>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            @click="getCurrent"
            height="26">
            Сurrent Month
          </v-btn>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            @click="getAllPeriod"
            height="26">
            All period
          </v-btn>
        </v-btn-toggle>
        <v-btn
            color="#010820"
            class="rounded-lg mr-5 fz-13 fw-600 text-none border-black"
            :class="{'mt-4': $vuetify.breakpoint.xsOnly}"
            height="30"
            style="background-color: #fff"
            @click="showDatepicker"
            outlined>
            Specify period
          </v-btn>
        <v-btn color="#F6F8F9" class="btn-expand text-none fz-14 fw-600 text-black" height="30" elevation="0" @click="expand">Expand
          <v-icon class="ml-2" size="14">$expand</v-icon>
        </v-btn>
      </div>
      <v-row class="ma-0 card-minimize-content">
        <v-col class="pb-0 pl-8 pb-5 pr-10 chart-linear d-flex flex-column justify-center">
          <v-row v-if="tab !== 2" class="ma-0 mb-4 justify-space-between relative align-end flex-grow-0">
            <v-menu
              offset-y
              right content-class="menu-shadow rounded-lg bg-white border-lightgray"
              style="max-width: 600px"
            >
              <template v-slot:activator="{ on, attrs }">
                <p class="ma-0 fz-18 font-weight-medium primary--text"
                  v-bind="attrs"
                  v-on="on">{{ selectedMonth.monthName }} <span class="text-black">{{ ' income' }}</span>
                  <v-icon size="8" class="ml-1 stroke-black">$ddown</v-icon>
                </p>
              </template>
              <v-list style="max-height: 200px" class="scroll">
                <v-list-item
                  v-for="month in months"
                  :key="month.id"
                  @click="changeMonth(month)"
                >
                  <v-list-item-title>{{ month.monthName }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <div class="fz-18 font-weight-medium">${{ selectedMonth.totalIncomeUsd.amount }}</div>
            <div :style="{'left': tooltip.income.x + 'px'}" v-if="tooltip.income" class="toolTip fz-13 fw-600 white--text one-line">
              {{ tooltip.income.name + ': ' }}
              {{ tooltip.income.symbol !== '₽' ? item.value.symbol : ''}}{{ tooltip.income.value | numberFormat}}{{ tooltip.income.symbol === '₽' ? tooltip.income.symbol : ''}}
            </div>
          </v-row>
          <svg v-show="tab !== 2 && selectedMonth.totalIncomeUsd.amount > 0" class="chart-line chart-line-income" style="height: 14px;"></svg>
          <v-row v-if="tab !== 1" :class="{'mt-6': tab === 0}" class="ma-0 mb-4 justify-space-between relative align-end flex-grow-0">
            <v-menu
              offset-y
              right content-class="menu-shadow rounded-lg bg-white border-lightgray"
              style="max-width: 600px"
            >
              <template v-slot:activator="{ on, attrs }">
                <p class="ma-0 fz-18 font-weight-medium primary--text"
                  v-bind="attrs"
                  v-on="on">{{ selectedMonth.monthName }} <span class="text-black">{{ ' expenses' }}</span>
                  <v-icon size="8" class="ml-1 stroke-black">$ddown</v-icon>
                </p>
              </template>
              <v-list style="max-height: 200px" class="scroll">
                <v-list-item
                  v-for="month in months"
                  :key="month.id"
                  @click="changeMonth(month)"
                >
                  <v-list-item-title>{{ month.monthName }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <div class="fz-18 font-weight-medium">${{ selectedMonth.totalExpensesUsd.amount }}</div>
            <div :style="{'left': tooltip.expenses.x + 'px'}" v-if="tooltip.expenses" class="toolTip fz-13 fw-600 white--text one-line">
              {{ tooltip.expenses.name + ': ' }}
              {{ tooltip.expenses.symbol !== '₽' ? tooltip.expenses.symbol : ''}}{{ tooltip.expenses.value | numberFormat}}{{ tooltip.expenses.symbol === '₽' ? tooltip.expenses.symbol : ''}}
            </div>
          </v-row>
          <svg v-show="tab !== 1 && selectedMonth.totalExpensesUsd.amount > 0" class="chart-line chart-line-expenses" style="height: 14px;"></svg>
        </v-col>
        <v-divider class="mb-5" vertical></v-divider>
        <v-col class="pa-0 pl-7 py-7 cards hidden">
          <v-row class="finance-cards-minimize ma-0 flex-grow-1">
            <v-slide-group
                show-arrows
                mandatory
                center-active
                v-model="model"
                prev-icon="mdi-chevron-left"
                next-icon="mdi-chevron-right"
                :class="{'leftBlur': blur.left, 'rightBlur': blur.right}"
                @click:next="changed"
                @click:prev="changed"
              >
                <v-slide-item v-for="card in accounts" :key="card.id">
                  <div
                    class="card hidden"
                    :style="{'background': card.type.color, 'color': card.type.fontColor}"
                  >
                    <div class="card__wrap">
                      <h3>{{ card.name }}</h3>
                      <p>{{ card.expirationDate ? 'Until ' + formatDate(card.expirationDate) : 'Unlimited' }}</p>
                      <img class="card__logo" :src="card.type.logo" :alt="card.name">
                      <span>{{ '**** **** **** ' + card.lastFourDigits }}</span>
                      <h2>${{ card.balance }}</h2>
                    </div>
                  </div>
                </v-slide-item>
            </v-slide-group>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <vc-date-picker
      class="inline-block h-full"
      locale="en"
      :class="{'date-picker-orders': $vuetify.breakpoint.mdAndUp, 'date-picker-mobile': $vuetify.breakpoint.smAndDown}"
      :popover="{ visibility: 'click' }"
      :columns="$vuetify.breakpoint.mdAndUp ? 2 : 1"
      mode="range"
      is-range
      @popoverWillShow="overlay = true"
      @popoverDidHide="overlay = false"
      :masks="{input: 'DD.MM.YYYY', weekdays: 'WW'}"
      v-model="date">
      <template v-slot="">
        <button
          ref="datepickerShow"
          style="display: none"
        ></button>
      </template>
    </vc-date-picker>
  </v-row>
</template>

<script>
import * as d3 from 'd3'
import numberFormat from '@/helpers/numberFormat'
import { Locale } from 'v-calendar'
const locale = new Locale()

export default {
  name: 'ChartLinear',
  props: ['months', 'minimize', 'accounts', 'selectedMonth', 'queryOptions'],
  filters: {
    numberFormat
  },
  data() {
    return {
      tab: 0,
      model: null,
      interval: 2,
      tabs: [
        {
          name: 'All'
        },
        {
          name: 'Income'
        },
        {
          name: 'Expenses'
        }
      ],
      colors: null,
      tooltip: {
        income: null,
        expenses: null
      },
      showMenu: null,
      blur: {
        left: false,
        right: true
      }
    }
  },
  computed: {
    date: {
      get () {
        const object = {
          end: this.queryOptions.dateEnd ? locale.parse(this.queryOptions.dateEnd, 'DD.MM.YYYY') : new Date(),
          start: this.queryOptions.dateStart ? locale.parse(this.queryOptions.dateStart, 'DD.MM.YYYY') : new Date()
        }
        return object
      },
      set (val) {
        this.interval = -1
        this.queryOptions.dateStart = val.start ? locale.format(val.start, 'DD.MM.YYYY') : ''
        this.queryOptions.dateEnd = val.end ? locale.format(val.end, 'DD.MM.YYYY') : ''
      }
    }
  },
  methods: {
    formatDate (date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    createChartLineIncome () {
      const vm = this
      const svg = d3.select('.chart-line-income')
      svg.selectAll('g').remove()
      const x = d3.scaleLinear()
        .domain([0, this.selectedMonth.totalIncomeUsd.amount]) //  <----- must change
        .range([0, svg._groups[0][0] ? svg._groups[0][0].parentNode.clientWidth - 72 : 0])
      const colors = d3.scaleOrdinal()
        .domain(this.selectedMonth.incomeCategories.map((d, i) => i))
        .range(d3.quantize(t => d3.interpolateSpectral(t * 0.99 + 0.1), this.selectedMonth.incomeCategories.length).reverse())
      const xArr = []
      this.selectedMonth.incomeCategories.map((el, i) => {
        el = i ? xArr[i - 1] * 1 + this.selectedMonth.incomeCategories[i - 1].value.amount * 1 : 0
        xArr.push(el)
      })
      const rect = svg
        .attr('height', '14')
        .append('g')
        .selectAll('rect')
        .data(this.selectedMonth.incomeCategories)
        .join('rect')
        .attr('x', (d, i) => x(xArr[i]))
        .attr('fill', (d, i) => colors(i))
        .attr('width', (d, i) => x(d.value.amount))
        .attr('height', '14px')
      rect.on('mouseover', function (event, d) {
        vm.tooltip.income = {
          x: d3.select(this).attr('x') * 1 + x(d.value.amount) / 2,
          name: d.category.name,
          value: d.value.amount,
          currency: d.value.currency,
          symbol: d.value.symbol
        }
      })
      rect.on('mouseleave', function () {
        vm.tooltip.income = null
      })
    },
    createChartLineExpenses () {
      const vm = this
      const svg = d3.select('.chart-line-expenses')
      svg.selectAll('g').remove()
      const x = d3.scaleLinear()
        .domain([0, this.selectedMonth.totalExpensesUsd.amount]) //  <----- must change
        .range([0, svg._groups[0][0] ? svg._groups[0][0].parentNode.clientWidth - 72 : 0])
      const colors = d3.scaleOrdinal()
        .domain(this.selectedMonth.expenseCategories.map((d, i) => i))
        .range(d3.quantize(t => d3.interpolateSpectral(t * 0.99 + 0.1), this.selectedMonth.expenseCategories.length).reverse())
      const xArr = []
      this.selectedMonth.expenseCategories.map((el, i) => {
        el = i ? xArr[i - 1] * 1 + this.selectedMonth.expenseCategories[i - 1].value.amount * 1 : 0
        xArr.push(el)
      })
      const rect = svg
        .attr('height', '14')
        .append('g')
        .selectAll('rect')
        .data(this.selectedMonth.expenseCategories)
        .join('rect')
        .attr('x', (d, i) => x(xArr[i]))
        .attr('fill', (d, i) => colors(i))
        .attr('width', (d, i) => x(d.value.amount))
        .attr('height', '14px')
      rect.on('mouseover', function (event, d) {
        vm.tooltip.expenses = {
          x: d3.select(this).attr('x') * 1 + x(d.value.amount) / 2,
          name: d.category.name,
          value: d.value.amount,
          currency: d.value.currency,
          symbol: d.value.symbol
        }
      })
      rect.on('mouseleave', function () {
        vm.tooltip.expenses = null
      })
    },
    getCurrent () {
      const start = new Date()
      const end = new Date()
      start.setDate(1)
      end.setMonth(end.getMonth() + 1)
      end.setDate(0)
      this.queryOptions.dateStart = locale.format(start, 'DD.MM.YYYY')
      this.queryOptions.dateEnd = locale.format(end, 'DD.MM.YYYY')
      const vm = this
      setTimeout(function () {
        vm.interval = 1
      }, 0)
    },
    getPrevious () {
      const start = new Date()
      const end = new Date()
      start.setDate(1)
      start.setMonth(end.getMonth() - 1)
      end.setDate(0)
      this.queryOptions.dateStart = locale.format(start, 'DD.MM.YYYY')
      this.queryOptions.dateEnd = locale.format(end, 'DD.MM.YYYY')
      const vm = this
      setTimeout(function () {
        vm.interval = 0
      }, 0)
    },
    getAllPeriod () {
      this.queryOptions.dateStart = null
      this.queryOptions.dateEnd = null
      const vm = this
      setTimeout(function () {
        vm.interval = 2
      }, 0)
    },
    showDatepicker () {
      const vm = this
      setTimeout(function () {
        vm.$refs.datepickerShow.click()
      }, 0)
    },
    expand() {
      this.$emit('update:minimize', false)
    },
    resize() {
      this.createChartLineIncome()
      this.createChartLineExpenses()
      this.changed()
    },
    changeMonth(month) {
      this.$emit('update:selectedMonth', month)
    },
    changed () {
      const accounts = document.querySelectorAll('.finance-cards-minimize')[0].childNodes[0]
      const vm = this
      setTimeout(function () {
        vm.blur.left = !accounts.querySelector('.v-slide-group__prev--disabled')
        vm.blur.right = !accounts.querySelector('.v-slide-group__next--disabled')
      })
    }
  },
  beforeDestroy: function () {
    const self = this
    window.removeEventListener('resize', function () {
      self.createChartLineIncome()
      self.createChartLineExpenses()
    })
  },
  watch: {
    selectedMonth() {
      this.createChartLineIncome()
      this.createChartLineExpenses()
    },
    tab() {
      this.queryOptions.type = this.tab === 1 ? 'INCOME' : this.tab === 2 ? 'EXPENSE' : null
    }
  },
  mounted() {
    this.resize()
    this.changed()
    if (this.selectedMonth.monthName) {
      this.createChartLineIncome()
      this.createChartLineExpenses()
    }
  }
}
</script>

<style lang="scss">
.card {
  &-minimize {
    background: #EEF2F7;
    border: 1px solid #EEF2F7;
    box-sizing: border-box;
    border-radius: 16px;
    width: 100%;
    &-content {
      flex-wrap: nowrap;
    }
    .chart-linear {
      flex: 0 0 50%;
    }
    .cards {
      flex: 0 0 50%;
      display: flex;
      min-height: 109px;
      align-items: flex-start;
    }
    .chart-line {
      border-radius: 4px;
      width: 100%;
    }
    .toolTip {
      position: absolute;
      bottom: -10px;
      background: #0E141E;
      border-radius: 8px;
      padding: 5px 10px;
      z-index: 100;
      transform: translateX(-50%);
    }
  }
  .finance-cards-minimize {
    display: flex;
    width: 500px;

    .v-item-group {
      align-items: center;
      position: relative;

    }
    .v-slide-group__content {
      padding-right: 20px;
    }
    .v-slide-group__next {
      position: absolute;
      z-index: 3;
      right: -5px;
      background-color: #fff;
      transform: translateX(-50%);
      padding-left: 3px;
      max-width: 30px !important;
      max-height: 30px !important;
      min-width: 30px !important;
      min-height: 30px !important;
      .v-icon.v-icon {
        color: #010820 !important;
        z-index: 2;
      }
    }

    .v-slide-group__prev {
      position: absolute;
      z-index: 3;
      left: 0;
      transform: translateX(-50%);
      background-color: #fff;
      max-width: 30px !important;
      max-height: 30px !important;
      min-width: 30px !important;
      min-height: 30px !important;
      .v-icon.v-icon {
        color: #010820 !important;
        z-index: 2;
      }
    }
    .rightBlur:after {
      content: '';
      position: absolute;
      width: 60px;
      right: 0px;
      top: 0;
      bottom: 0;
      z-index: 1;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #EEF2F7 100%);
      backdrop-filter: blur(1px);
    }
    .leftBlur:before {
      content: '';
      position: absolute;
      width: 60px;
      left: 0px;
      top: 0;
      bottom: 0;
      z-index: 1;
      background: linear-gradient(90deg, #EEF2F7 0%, rgba(255, 255, 255, 0) 100%);
      backdrop-filter: blur(2px);
    }
    .card {
      height: 86px;
      max-width: 152px;
      min-width: 152px;
      padding: 8px 10px;
      flex-grow: 1;
      border-radius: 9px;
      position: relative;
      color: rgba(255, 255, 255, .8);

      &:not(:last-child) {
        margin-right: 5px;
      }

      &__wrap {
        z-index: 1;
        position: relative;
      }

      &__logo {
        position: absolute;
        right: -2px;
        top: 3px;
        max-width: 25px;
        max-height: 25px;
      }
      h3 {
        font-size: 8px;
        font-weight: 500;
        line-height: 10px;
      }

      p {
        font-weight: bold;
        font-size: 8px;
        line-height: 10px;
        margin-bottom: 0px;
      }

      span {
        font-weight: 600;
        font-size: 8px;
        line-height: 10px;
      }

      h2 {
        margin-top: 5px;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;

        small {
          font-size: 9px;
        }
      }
    }
    .card-transparent {
      &:after {
        content: url("../../Svg/radial-transparent.svg");
        transform: scale(.57);
        position: absolute;
        right: -25px;
        bottom: -33px;
      }
    }
  }
}
.btn-expand {
  background: #F6F8F9;
  border-radius: 8px !important;
}
.linear-date-picker {
  position: absolute !important;
  top: 10px;
  right: 30px;
}
</style>
