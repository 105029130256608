<template>
  <v-row class="finance-cards mb-15 mx-0 mt-0 flex-grow-0">
    <v-slide-group
      show-arrows
      mandatory
      center-active
      v-model="model"
      :class="{'leftBlur': blur.left, 'rightBlur': blur.right}"
      @click:next="changed"
      @click:prev="changed"
      prev-icon="mdi-chevron-left"
      next-icon="mdi-chevron-right"
      >
        <v-slide-item v-for="card in accounts" :key="card.id">
          <div
            class="card hidden"
            :style="{'background': card.type.color, 'color': card.type.fontColor}"
          >
            <div class="card__wrap">
              <h3>{{ card.name }}</h3>
              <p>{{ card.expirationDate ? 'Until ' + formatDate(card.expirationDate) : 'Unlimited' }}</p>
              <img class="card__logo" :src="card.type.logo" :alt="card.name">
              <span>{{ '**** **** **** ' + card.lastFourDigits }}</span>
              <h2>${{ card.balance }}</h2>
            </div>
          </div>
        </v-slide-item>
    </v-slide-group>
  </v-row>
</template>

<script>
import numberFormat from '@/helpers/numberFormat'
import { Locale } from 'v-calendar'
const locale = new Locale()

export default {
  name: 'FinanceCards',
  props: ['accounts'],
  filters: {
    numberFormat
  },
  data () {
    return {
      model: null,
      blur: {
        left: false,
        right: true
      }
    }
  },
  methods: {
    formatDate (date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    changed () {
      const accounts = document.querySelectorAll('.finance-cards')[0].childNodes[0]
      const vm = this
      setTimeout(function () {
        vm.blur.left = !accounts.querySelector('.v-slide-group__prev--disabled')
        vm.blur.right = !accounts.querySelector('.v-slide-group__next--disabled')
      })
    }
  },
  mounted() {
    this.changed()
  }
}
</script>

<style lang="scss">
.finance-cards {
  display: flex;
  .v-item-group {
    align-items: center;
    position: relative;
  }
  .v-slide-group__wrapper {
    position: relative;
  }
  .rightBlur:after {
    content: '';
    position: absolute;
    width: 60px;
    right: -7px;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    backdrop-filter: blur(1px);
  }
  .leftBlur:before {
    content: '';
    position: absolute;
    width: 60px;
    left: -7px;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    backdrop-filter: blur(2px);
  }
  .v-slide-group__next {
    position: absolute;
    right: 5px;
    background-color: #fff;
    z-index: 5;
    span {
      z-index: 3;
    }
  }
  .v-slide-group__prev {
    position: absolute;
    left: -14px;
    background-color: #fff;
    z-index: 5;
    span {
      z-index: 3;
    }
  }
  .card {
    height: 150px;
    max-width: 250px;
    min-width: 250px;
    padding: 15px 20px;
    flex-grow: 1;
    border-radius: 16px;
    position: relative;
    &:not(:last-child) {
      margin-right: 16px;
    }
    &__wrap {
      z-index: 1;
      position: relative;
    }
    h3 {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      opacity: .7;
    }
    p {
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
      margin-bottom: 9px;
    }
    &__logo {
      position: absolute;
      right: -2px;
      top: 3px;
      max-width: 45px;
      max-height: 45px;
    }
    span {
      font-size: 14px;
      font-weight: 600;
    }
    h2 {
      margin-top: 11px;
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
      small {
        font-size: 16px;
      }
    }
  }
}

</style>
