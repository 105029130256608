<template>
  <v-card style="max-height: 100vh" class="d-flex flex-column height-100 finance-operation">
<!--        header -->
    <v-toolbar
      elevation="0"
      class="bb-gray flex-grow-0"
      style="z-index: 1"
    >
      <v-col>
        <v-row></v-row>
      </v-col>
      <v-col class="pa-0">
        <v-row class="ma-0">
          <v-spacer></v-spacer>
          <v-toolbar-title class="fz-24 font-weight-bold" v-if="!operation.id">Create operation</v-toolbar-title>
          <v-toolbar-title class="fz-24 font-weight-bold" v-else>{{ readOnly ? 'View operation ' + operation.id : 'Edit operation ' + operation.id }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-row>
      </v-col>
      <v-col>
        <v-row>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-icon class="mr-5 close-icon" @click="closeOperation">$close</v-icon><!-- @click="closeEdit"-->
          </v-toolbar-items>
        </v-row>
      </v-col>
    </v-toolbar>
<!--        main info -->
    <v-card elevation="0" class="py-0 flex-grow-1 scroll">
      <v-row class="ma-0 px-0 pt-10 pb-3 align-center height-100">
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
        <v-form ref="form" :class="{'px-3': $vuetify.breakpoint.smAndDown}">
          <v-col class="pa-0 min-width-600">
            <v-row class="mx-0 mb-3 mt-12 fz-20 fw-600 text-black">Operation Details</v-row>
            <v-divider class="black bb-1 mb-10"></v-divider>
            <v-row class="ma-0 mb-3">
              <span class="fz-14 text-gray width-140 my-auto mr-8">Type of Payment</span>
              <v-text-field
                v-if="operation.id && readOnly"
                filled
                single-line
                :value="operation.type ? operation.type : ''"
                class="rounded-lg text-black mb-n4 fz-14 font-weight-medium select-dash border-none"
                dense
                readonly
              >
                <template v-slot:prepend-inner>
                   <v-sheet
                    class="absolute pointer"
                    elevation="0"
                    color="transparent"
                    style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                   ></v-sheet>
                 </template>
                 <template v-slot:append>
                   <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                 </template>
              </v-text-field>
              <v-menu attach="#select16" v-else content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100">
                <template v-slot:activator="{ on, attrs }">
                  <v-col class="pa-0">
                    <v-text-field
                      outlined
                      single-line
                      :value="operation.type ? operation.type : ''"
                      class="rounded-lg text-black mb-n4 fz-14 font-weight-medium select-dash input-border"
                      dense
                      readonly
                      :rules="[rules.required]"
                    >
                      <template v-slot:prepend-inner>
                         <v-sheet
                            v-bind="attrs"
                            v-on="on"
                            class="absolute"
                            elevation="0"
                            color="transparent"
                            style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                         ></v-sheet>
                       </template>
                       <template v-slot:append>
                         <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                       </template>
                    </v-text-field>
                    <div id="select16" class="relative z-index-400"></div>
                  </v-col>
                </template>
                <v-card class="rounded-lg mt-1 hidden" elevation="0">
                  <v-card-text class="px-0 py-1">
                    <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                      <v-list-item
                        v-for="(item, index) in lists.paymentTypes"
                        :key="index"
                        @click="clearCategory(item)"
                        style="min-height: 30px"
                        class="px-0 py-1"
                      >
                        <p class="ma-0 px-2 fz-12 text-black">{{ item }}</p>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-row>
            <v-row class="ma-0 mb-3">
              <span class="fz-14 text-gray width-140 my-auto mr-8">Category</span>
              <v-text-field
                v-if="operation.id && readOnly"
                filled
                single-line
                :value="operation.category ? operation.category.name : ''"
                class="rounded-lg text-black mb-n4 fz-14 font-weight-medium select-dash border-none"
                dense
                readonly
              >
                <template v-slot:prepend-inner>
                   <v-sheet
                    class="absolute pointer"
                    elevation="0"
                    color="transparent"
                    style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                   ></v-sheet>
                 </template>
                 <template v-slot:append>
                   <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                 </template>
              </v-text-field>
              <v-menu attach="#select17" v-else content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100">
                <template v-slot:activator="{ on, attrs }">
                  <v-col class="pa-0">
                    <v-text-field
                      outlined
                      single-line
                      :value="operation.category ? operation.category.name : ''"
                      class="rounded-lg text-black mb-n4 fz-14 font-weight-medium select-dash input-border"
                      dense
                      readonly
                      :rules="[rules.required]"
                    >
                      <template v-slot:prepend-inner>
                         <v-sheet
                            v-bind="attrs"
                            v-on="on"
                            class="absolute"
                            elevation="0"
                            color="transparent"
                            style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                         ></v-sheet>
                       </template>
                       <template v-slot:append>
                         <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                       </template>
                    </v-text-field>
                    <div id="select17" class="relative z-index-400"></div>
                  </v-col>
                </template>
                <v-card class="rounded-lg mt-1 hidden" elevation="0">
                  <v-card-text class="px-0 py-1">
                    <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                      <v-list-item
                        v-for="(item, index) in filteredCategories"
                        :key="index"
                        @click="clearData(item)"
                        style="min-height: 30px"
                        class="px-0 py-1"
                      >
                        <p class="ma-0 px-2 fz-12 text-black">{{ item.name }}</p>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-row>
            <v-row class="ma-0">
              <span class="fz-14 text-gray width-140 mt-2 mr-8">Description</span>
              <v-textarea
                :outlined="!operation.id || !readOnly"
                :readonly="operation.id && readOnly"
                :filled="operation.id && readOnly"
                v-model="operation.description"
                class="rounded-lg fz-14 text-black font-weight-medium"
                :class="{'border-none': operation.id && readOnly, 'input-border': !operation.id || !readOnly }"
                height="200"
                no-resize
                placeholder="Brief description of the operation..."
              ></v-textarea>
            </v-row>
            <v-row class="ma-0">
              <span class="fz-14 text-gray width-140 mt-2 mr-8"></span>
              <v-col class="pa-0" v-if="operation.category && operation.category.required === 'ORDER'">
                <span class="fz-14 text-gray">Client</span>
                <v-autocomplete
                  dense
                  :items="clients"
                  v-model="client"
                  :outlined="!operation.id || !readOnly"
                  :readonly="operation.id && readOnly"
                  :filled="operation.id && readOnly"
                  :search-input.sync="searchOrder.client"
                  no-filter
                  :menu-props="{ contentClass: 'operation-dropdown'}"
                  required
                  :clearable="!operation.id || !readOnly"
                  hide-no-data
                  item-color="#010820"
                  item-text="client.name"
                  return-object
                  hide-selected
                  :rules="[rules.requiredOrder]"
                  class="rounded-lg fz-14 mb-n2 font-weight-medium inline-input select-dash input-clear-small"
                  :class="{'border-none': operation.id && readOnly, 'input-border': !operation.id || !readOnly }"
                  >
                </v-autocomplete>
                <span class="fz-14 text-gray">Project</span>
                <v-autocomplete
                  dense
                  :items="filteredOrders"
                  v-model="operation.order"
                  :outlined="!operation.id || !readOnly"
                  :readonly="operation.id && readOnly"
                  :filled="operation.id && readOnly"
                  :search-input.sync="searchOrder.order"
                  :menu-props="{ contentClass: 'operation-dropdown'}"
                  no-filter
                  required
                  :clearable="!operation.id || !readOnly"
                  hide-no-data
                  item-color="#010820"
                  item-text="name"
                  return-object
                  hide-selected
                  @change="setClient"
                  :rules="[rules.requiredOrder]"
                  class="rounded-lg fz-14 mb-n2 font-weight-medium inline-input select-dash input-clear-small"
                  :class="{'border-none': operation.id && readOnly, 'input-border': !operation.id || !readOnly }"
                  >
                </v-autocomplete>
              </v-col>
              <v-col class="pa-0" v-else-if="operation.category && operation.category.required === 'WORKER'">
                <span class="fz-14 text-gray">Worker</span>
                <v-autocomplete
                  dense
                  :items="workers"
                  v-model="operation.worker"
                  :outlined="!operation.id || !readOnly"
                  :readonly="operation.id && readOnly"
                  :filled="operation.id && readOnly"
                  :search-input.sync="searchWorker"
                  no-filter
                  :menu-props="{ contentClass: 'operation-dropdown'}"
                  required
                  :clearable="!operation.id || !readOnly"
                  hide-no-data
                  item-color="#010820"
                  item-text="businessName"
                  return-object
                  hide-selected
                  :rules="[rules.requiredWorker]"
                  class="rounded-lg fz-14 mb-n2 font-weight-medium inline-input select-dash input-clear-small"
                  :class="{'border-none': operation.id && readOnly, 'input-border': !operation.id || !readOnly }"
                  >
                </v-autocomplete>
              </v-col>
<!--              <v-col class="pa-0" v-else-if="operation.category && operation.category.required === 'ACCOUNT'">-->
<!--                <span class="fz-14 text-gray">Transfer to</span>-->
<!--                <v-text-field-->
<!--                  v-if="operation.id && readOnly"-->
<!--                  filled-->
<!--                  single-line-->
<!--                  :value="operation.transfer ? operation.transfer.name : ''"-->
<!--                  class="rounded-lg text-black fz-14 font-weight-medium select-dash border-none"-->
<!--                  dense-->
<!--                  readonly-->
<!--                >-->
<!--                  <template v-slot:prepend-inner>-->
<!--                     <v-sheet-->
<!--                      class="absolute pointer"-->
<!--                      elevation="0"-->
<!--                      color="transparent"-->
<!--                      style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"-->
<!--                     ></v-sheet>-->
<!--                   </template>-->
<!--                   <template v-slot:append>-->
<!--                     <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>-->
<!--                   </template>-->
<!--                </v-text-field>-->
<!--                <v-menu attach="#select18" v-else content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100">-->
<!--                  <template v-slot:activator="{ on, attrs }">-->
<!--                    <v-col class="pa-0">-->
<!--                      <v-text-field-->
<!--                        outlined-->
<!--                        single-line-->
<!--                        :value="operation.transfer ? operation.transfer.name : ''"-->
<!--                        class="rounded-lg text-black fz-14 font-weight-medium select-dash input-border"-->
<!--                        dense-->
<!--                        readonly-->
<!--                        :rules="[rules.requiredAccount]"-->
<!--                      >-->
<!--                        <template v-slot:prepend-inner>-->
<!--                           <v-sheet-->
<!--                              v-bind="attrs"-->
<!--                              v-on="on"-->
<!--                              class="absolute"-->
<!--                              elevation="0"-->
<!--                              color="transparent"-->
<!--                              style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"-->
<!--                           ></v-sheet>-->
<!--                         </template>-->
<!--                         <template v-slot:append>-->
<!--                           <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>-->
<!--                         </template>-->
<!--                      </v-text-field>-->
<!--                      <div id="select18" class="relative z-index-400"></div>-->
<!--                    </v-col>-->
<!--                  </template>-->
<!--                  <v-card class="rounded-lg mt-1 hidden" elevation="0">-->
<!--                    <v-card-text class="px-0 py-1">-->
<!--                      <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">-->
<!--                        <v-list-item-->
<!--                          v-for="(item, index) in accounts"-->
<!--                          :key="index"-->
<!--                          @click="operation.transfer = item"-->
<!--                          style="min-height: 30px"-->
<!--                          class="px-0 py-1"-->
<!--                        >-->
<!--                          <p class="ma-0 px-2 fz-12 text-black">{{ item.name }}</p>-->
<!--                        </v-list-item>-->
<!--                      </v-list>-->
<!--                    </v-card-text>-->
<!--                  </v-card>-->
<!--                </v-menu>-->
<!--              </v-col>-->
            </v-row>
            <v-divider class="mt-0" />
            <v-row class="ma-0 mb-3">
              <span class="fz-14 text-gray width-140 my-auto mr-8">Account <span class="text-gray" v-if="operation.category && operation.category.required === 'ACCOUNT'">from</span></span>
              <v-text-field
                v-if="operation.id && readOnly"
                filled
                single-line
                :value="operation.account ? operation.account.name : ''"
                class="rounded-lg mb-n4 text-black fz-14 font-weight-medium select-dash border-none"
                dense
                readonly
              >
                <template v-slot:prepend-inner>
                   <v-sheet
                    class="absolute pointer"
                    elevation="0"
                    color="transparent"
                    style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                   ></v-sheet>
                 </template>
                 <template v-slot:append>
                   <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                 </template>
              </v-text-field>
              <v-menu attach="#select19" v-else content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100">
                <template v-slot:activator="{ on, attrs }">
                  <v-col class="pa-0">
                    <v-text-field
                      outlined
                      single-line
                      :value="operation.account ? operation.account.name : ''"
                      class="rounded-lg mb-n4 text-black fz-14 font-weight-medium select-dash input-border"
                      dense
                      readonly
                      :rules="[rules.required]"
                    >
                      <template v-slot:prepend-inner>
                         <v-sheet
                            v-bind="attrs"
                            v-on="on"
                            class="absolute"
                            elevation="0"
                            color="transparent"
                            style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                         ></v-sheet>
                       </template>
                       <template v-slot:append>
                         <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                       </template>
                    </v-text-field>
                    <div id="select19" class="relative z-index-400"></div>
                  </v-col>
                </template>
                <v-card class="rounded-lg mt-1 hidden" elevation="0">
                  <v-card-text class="px-0 py-1">
                    <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                      <v-list-item
                        v-for="(item, index) in accounts"
                        :key="index"
                        @click="operation.account = item"
                        style="min-height: 30px"
                        class="px-0 py-1"
                      >
                        <p class="ma-0 px-2 fz-12 text-black">{{ item.name }}</p>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-row>
            <v-row class="ma-0 mb-3" v-if="operation.category && operation.category.required === 'ACCOUNT'">
                <span class="fz-14 text-gray width-140 my-auto mr-8">Account to</span>
                <v-text-field
                  v-if="operation.id && readOnly"
                  filled
                  single-line
                  :value="operation.transferAccount ? operation.transferAccount.name : ''"
                  class="rounded-lg mb-n4 text-black fz-14 font-weight-medium select-dash border-none"
                  dense
                  readonly
                >
                  <template v-slot:prepend-inner>
                     <v-sheet
                      class="absolute pointer"
                      elevation="0"
                      color="transparent"
                      style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                     ></v-sheet>
                   </template>
                   <template v-slot:append>
                     <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                   </template>
                </v-text-field>
                <v-menu attach="#select18" v-else content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100">
                  <template v-slot:activator="{ on, attrs }">
                    <v-col class="pa-0">
                      <v-text-field
                        outlined
                        single-line
                        :value="operation.transferAccount ? operation.transferAccount.name : ''"
                        class="rounded-lg mb-n4 text-black fz-14 font-weight-medium select-dash input-border"
                        dense
                        readonly
                        :rules="[rules.requiredAccount]"
                      >
                        <template v-slot:prepend-inner>
                           <v-sheet
                              v-bind="attrs"
                              v-on="on"
                              class="absolute"
                              elevation="0"
                              color="transparent"
                              style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                           ></v-sheet>
                         </template>
                         <template v-slot:append>
                           <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                         </template>
                      </v-text-field>
                      <div id="select18" class="relative z-index-400"></div>
                    </v-col>
                  </template>
                  <v-card class="rounded-lg mt-1 hidden" elevation="0">
                    <v-card-text class="px-0 py-1">
                      <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                        <v-list-item
                          v-for="(item, index) in accounts"
                          :key="index"
                          @click="operation.transferAccount = item"
                          style="min-height: 30px"
                          class="px-0 py-1"
                        >
                          <p class="ma-0 px-2 fz-12 text-black">{{ item.name }}</p>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-row>
            <v-row class="ma-0 mb-3">
              <span class="fz-14 text-gray width-140 my-auto mr-8">Custom Date</span>
              <v-text-field
                :outlined="!operation.id || !readOnly"
                :readonly="operation.id && readOnly"
                :filled="operation.id && readOnly"
                single-line
                v-mask="['##.##.####']"
                placeholder="DD.MM.YYYY"
                v-model="customDate"
                class="rounded-lg mb-n4 fz-14 text-black font-weight-medium"
                :class="{'border-none': operation.id && readOnly, 'input-border': !operation.id || !readOnly }"
                dense
                :rules="[rules.dateBirth]"
              ></v-text-field>
            </v-row>
            <v-row class="ma-0 mb-3">
              <span class="fz-14 text-gray width-140 my-auto mr-8">Amount</span>
              <v-text-field
                :outlined="!operation.id || !readOnly"
                :readonly="operation.id && readOnly"
                :filled="operation.id && readOnly"
                single-line
                type="number"
                v-model.number="operation.amount.amount"
                class="rounded-lg mr-3 fz-14 text-black font-weight-medium input-qty"
                :class="{'border-none': operation.id && readOnly, 'input-border': !operation.id || !readOnly }"
                dense
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                v-if="operation.id && readOnly"
                filled
                single-line
                :value="operation.amount.currency ? operation.amount.currency : ''"
                class="rounded-lg mb-n4 text-black fz-14 font-weight-medium select-dash border-none min-width-120 width-120"
                dense
                readonly
              >
                <template v-slot:prepend-inner>
                   <v-sheet
                    class="absolute pointer"
                    elevation="0"
                    color="transparent"
                    style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                   ></v-sheet>
                 </template>
                 <template v-slot:append>
                   <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                 </template>
              </v-text-field>
              <v-menu attach="#select20" v-else content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100">
                <template v-slot:activator="{ on, attrs }">
                  <v-col class="pa-0">
                    <v-text-field
                      outlined
                      single-line
                      :value="operation.amount.currency ? operation.amount.currency : ''"
                      class="rounded-lg mb-n4 text-black fz-14 font-weight-medium select-dash input-border width-120"
                      dense
                      readonly
                      :rules="[rules.required]"
                    >
                      <template v-slot:prepend-inner>
                         <v-sheet
                            v-bind="attrs"
                            v-on="on"
                            class="absolute"
                            elevation="0"
                            color="transparent"
                            style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                         ></v-sheet>
                       </template>
                       <template v-slot:append>
                         <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                       </template>
                    </v-text-field>
                    <div id="select20" class="relative z-index-400"></div>
                  </v-col>
                </template>
                <v-card class="rounded-lg mt-1 hidden" elevation="0">
                  <v-card-text class="px-0 py-1">
                    <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                      <v-list-item
                        v-for="(item, index) in lists.currency"
                        :key="index"
                        @click="operation.amount.currency = item.value"
                        style="min-height: 30px"
                        class="px-0 py-1"
                      >
                        <p class="ma-0 px-2 fz-12 text-black">{{ item.name }}</p>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-row>
<!--            <v-divider class="mt-0" v-if="operation.category.required !== 'ACCOUNT'" />-->
<!--            <v-row v-if="operation.category.required !== 'ACCOUNT'" class="ma-0 mb-3">-->
<!--              <span class="fz-14 text-gray width-140 mt-3 mr-8">Attachment</span>-->
<!--              <v-col class="pa-0">-->
<!--                <v-text-field-->
<!--                  prepend-icon=""-->
<!--                  append-icon=""-->
<!--                  :clearable="false"-->
<!--                  class="rounded-lg fz-14 text-black font-weight-medium inline-input select-dash input-border file-input-readonly"-->
<!--                  outlined-->
<!--                  dense-->
<!--                  :value="fileNameSplit(input.url)"-->
<!--                  v-for="input in operation.attachments"-->
<!--                  :key="input.id"-->
<!--                >-->
<!--                  <template v-slot:prepend-inner>-->
<!--                    <v-img-->
<!--                      :src="getImg(input.url)"-->
<!--                      width="30" height="30" class="mt-n1"></v-img>-->
<!--                  </template>-->
<!--                  <template v-slot:append>-->
<!--                    <v-dialog-->
<!--                        transition="dialog-bottom-transition"-->
<!--                        max-width="370"-->
<!--                        content-class="rounded-xl"-->
<!--                        overlay-color="rgba(9, 24, 73, 0.2)"-->
<!--                        overlay-opacity="1"-->
<!--                      >-->
<!--                      <template v-slot:activator="{ on, attrs }">-->
<!--                        <v-list-item-title-->
<!--                          class="fz-14 fw-600 d-flex align-center pointer"-->
<!--                          v-bind="attrs"-->
<!--                          v-on="readOnly ? null : on"-->
<!--                        >-->
<!--                          <v-icon-->
<!--                            size="30"-->
<!--                            style="padding: 5px"-->
<!--                            class="ml-5 mt-n1"-->
<!--                            :class="{'stroke-primary': !readOnly, 'stroke-lgray': readOnly }"-->
<!--                          >$delete</v-icon>-->
<!--                          <span-->
<!--                            class="mr-6 mt-n1"-->
<!--                            :class="{'primary&#45;&#45;text': !readOnly, 'text-lgray': readOnly }"-->
<!--                          >Delete</span>-->
<!--                        </v-list-item-title>-->
<!--                      </template>-->
<!--                      <template v-slot:default="dialog">-->
<!--                        <v-card class="pa-7">-->
<!--                          <v-card-text class="py-0">-->
<!--                            <div class="fz-18 text-center text-black">Do you really want to remove this file?</div>-->
<!--                          </v-card-text>-->
<!--                          <v-divider></v-divider>-->
<!--                          <v-card-actions class="justify-space-between pa-0">-->
<!--                            <v-btn-->
<!--                              class="rounded-lg text-none fz-16 fw-600"-->
<!--                              outlined-->
<!--                              width="150"-->
<!--                              height="40"-->
<!--                              @click="dialog.value = false"-->
<!--                            >Return</v-btn>-->
<!--                            <v-btn-->
<!--                              elevation="0"-->
<!--                              color="#FF0000"-->
<!--                              width="150"-->
<!--                              height="40"-->
<!--                              class="rounded-lg white&#45;&#45;text text-none fz-16 fw-600"-->
<!--                              @click="deleteFile(input); dialog.value = false"-->
<!--                            >-->
<!--                              <v-icon left class="stroke-white">-->
<!--                                $delete-->
<!--                              </v-icon>-->
<!--                              Delete</v-btn>-->
<!--                          </v-card-actions>-->
<!--                        </v-card>-->
<!--                      </template>-->
<!--                    </v-dialog>-->
<!--                  </template>-->
<!--                </v-text-field>-->
<!--              </v-col>-->
<!--            </v-row>-->
          </v-col>
        </v-form>
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
      </v-row>
    </v-card>
<!--    bottom navigation-->
    <v-row class="ma-0 py-5 px-8 white bt-gray flex-grow-0">
        <v-btn
          :width="$vuetify.breakpoint.lgAndUp ? '160' : $vuetify.breakpoint.smAndUp ? '120' : '80'"
          height="40"
          class="mr-5 rounded-lg fz-16 fw-600 text-none"
          elevation="0"
          color="primary"
          @click="$emit('update:readOnly', false)"
          v-if="operation.id && readOnly"
          outlined>
            <v-icon size="20" class="stroke-primary mr-1">$edit</v-icon>
            Edit</v-btn>
        <v-btn
          width="160"
          height="40"
          class="mr-5 rounded-lg fz-16 fw-600 text-none ml-auto"
          :class="{'ml-n15': $vuetify.breakpoint.smAndDown}"
          elevation="0"
          color="primary"
          @click="saveOperation"
          v-if="!operation.id || !readOnly"
          >
            Save
        </v-btn>
      </v-row>
  </v-card>
</template>

<script>

import gql from 'graphql-tag'
import { Locale } from 'v-calendar'
const locale = new Locale()

export default {
  name: 'FinanceOperation',
  props: ['financeOperationShow', 'categories', 'accounts', 'operation', 'readOnly'],
  data() {
    return {
      stepper: 1,
      wordFile: null,
      wordFileName: '',
      files: [],
      messages: [],
      rules: {
        required: value => (value !== null && String(value).length > 0) || 'Required.',
        requiredOrder: value => this.operation.category.required === 'ORDER' ? !!value || 'Required.' : true,
        requiredWorker: value => this.operation.category.required === 'WORKER' ? !!value || 'Required.' : true,
        requiredAccount: value => this.operation.category.required === 'ACCOUNT' ? !!value || 'Required.' : true,
        dateBirth: value => {
          if (value === '') return true
          if (!/^\d{1,2}\.\d{1,2}\.\d{4}$/.test(value)) return 'Date is invalid'
          // Parse the date parts to integers
          const parts = value.split('.')
          const day = parseInt(parts[0], 10)
          const month = parseInt(parts[1], 10)
          const year = parseInt(parts[2], 10)
          // Check the ranges of month and year
          if (year < 1000 || year > 3000 || month === 0 || month > 12) { return 'Date is invalid' }
          const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
          // Adjust for leap years
          if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) { monthLength[1] = 29 }
          // Check the range of the day
          return (day > 0 && day <= monthLength[month - 1]) || 'Date is invalid'
        }
      },
      searchOrder: {
        client: '',
        order: ''
      },
      searchWorker: '',
      lists: {
        paymentTypes: ['INCOME', 'EXPENSE', 'TRANSFER'],
        currency: [
          {
            name: '$ (USD)',
            value: 'USD'
          },
          {
            name: '€ (EUR)',
            value: 'EUR'
          },
          {
            name: '₽ (RUB)',
            value: 'RUB'
          }
        ]
      },
      addFileShow: true,
      client: null,
      clients: [],
      // order: null,
      orders: [],
      // worker: null,
      workers: []
    }
  },
  methods: {
    formatDate (date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    setClient() {
      if (this.operation.order) {
        this.clients = [this.operation.order]
        this.client = this.operation.order
      }
    },
    setOrder() {
      if (this.operation.order) {
        this.orders = [this.operation.order]
        this.setClient()
      }
    },
    setWorker() {
      if (this.operation.worker) {
        this.workers = [this.operation.worker]
      }
    },
    clearCategory(item) {
      this.operation.type = item
      if (this.operation.category) this.operation.category = { name: '' }
    },
    clearData(item) {
      this.operation.category = item
      this.client = null
      this.operation.order = null
      this.operation.worker = null
      this.files = []
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    closeOperation() {
      this.files = []
      this.$emit('update:financeOperationShow', false)
    },
    async getOrders () {
      if (this.searchOrder.client && !(this.client && this.client.client.name === this.searchOrder.client)) {
        await this.$apollo.query({
          query: gql`query getOrders ($input: DesignOrderFilterInput! ) {
            design {
              orders {
                paginatedOrders(input: $input) {
                  data {
                    id
                    name
                    client {
                      id
                      name
                    }
                  }
                }
              }
            }
          }`,
          variables: {
            input: {
              searchClient: this.searchOrder.client,
              searchName: this.searchOrder.order
            }
          },
          fetchPolicy: 'no-cache'
        }).then((data) => {
          this.clients = data.data.design.orders.paginatedOrders.data
          this.orders = data.data.design.orders.paginatedOrders.data
        }).catch((err) => {
          console.log(err)
        })
      } else if (this.searchOrder.order) {
        await this.$apollo.query({
          query: gql`query getOrders ($input: DesignOrderFilterInput! ) {
            design {
              orders {
                paginatedOrders(input: $input) {
                  data {
                    id
                    name
                    client {
                      id
                      name
                    }
                  }
                }
              }
            }
          }`,
          variables: {
            input: {
              searchName: this.searchOrder.order
            }
          },
          fetchPolicy: 'no-cache'
        }).then((data) => {
          this.orders = data.data.design.orders.paginatedOrders.data
        }).catch((err) => {
          console.log(err)
        })
      }
    },
    async getWorkers () {
      await this.$apollo.query({
        query: gql`query getWorkers ($input: DesignerFilterInput! ) {
          team {
            paginatedDesigners(input: $input) {
              data {
                businessName
                id
              }
            }
          }
        }`,
        variables: {
          input: {
            businessName: this.searchWorker
          }
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.workers = data.data.team.paginatedDesigners.data
      }).catch((err) => {
        console.log(err)
      })
    },
    async saveOperation () {
      if (this.$refs.form.validate() && !this.operation.id) {
        await this.$apollo.mutate({
          mutation: gql`mutation saveOperation ($input: OperationCreateInput! ) {
            finance {
              createOperation(input: $input) {
                id
                attachmentsUploadUrl
              }
            }
          }`,
          variables: {
            input: {
              type: this.operation.type,
              categoryId: this.operation.category.id,
              accountId: this.operation.account.id,
              date: this.operation.date ? this.operation.date : this.formatDate(new Date()),
              amount: {
                amount: this.operation.amount.amount,
                currency: this.operation.amount.currency
              },
              description: this.operation.description,
              orderId: this.operation.category.required === 'ORDER' ? this.operation.order.id : null,
              transferAccountId: this.operation.category.required === 'ACCOUNT' ? this.operation.transferAccount.id : null,
              workerId: this.operation.category.required === 'WORKER' ? this.operation.worker.id : null
            }
          },
          fetchPolicy: 'no-cache'
        }).then((data) => {
          const item = data.data.finance.createOperation
          this.$emit('addSnack', item.id, 'Success', 'Operation #' + item.id + ' was created with success')
          this.$emit('updateTable')
          this.closeOperation()
          this.$emit('resetOperation')
          this.resetValidation()
        }).catch((err) => {
          this.$emit('addSnack', 'item.name', 'Error', err)
          console.log(err)
        })
      } else if (this.$refs.form.validate()) {
        await this.$apollo.mutate({
          mutation: gql`mutation saveOperation ($input: OperationUpdateInput! ) {
            finance {
              updateOperation(input: $input) {
                id
                attachmentsUploadUrl
              }
            }
          }`,
          variables: {
            input: {
              id: this.operation.id,
              type: this.operation.type,
              categoryId: this.operation.category.id,
              accountId: this.operation.account.id,
              date: this.operation.date ? this.operation.date : this.formatDate(new Date()),
              amount: {
                amount: this.operation.amount.amount,
                currency: this.operation.amount.currency
              },
              description: this.operation.description,
              orderId: this.operation.category.required === 'ORDER' ? this.operation.order.id : null,
              transferAccountId: this.operation.category.required === 'ACCOUNT' ? this.operation.transferAccount.id : null,
              workerId: this.operation.category.required === 'WORKER' ? this.operation.worker.id : null
            }
          },
          fetchPolicy: 'no-cache'
        }).then((data) => {
          const item = data.data.finance.updateOperation
          this.$emit('addSnack', item.id, 'Success', 'Operation #' + item.id + ' was created with success')
          this.$emit('updateTable')
          this.closeOperation()
          this.$emit('resetOperation')
          this.resetValidation()
        }).catch((err) => {
          this.$emit('addSnack', 'item.name', 'Error', err)
          console.log(err)
        })
      }
    }
  },
  computed: {
    filteredCategories() {
      const arr = this.categories.filter(el => el[this.operation.type === 'INCOME' ? 'forIncome' : this.operation.type === 'EXPENSE' ? 'forExpense' : this.operation.type === 'TRANSFER' ? 'forTransfer' : ''])
      return arr
    },
    filteredOrders() {
      const arr = this.client ? this.orders.filter(el => el.client.id === this.client.client.id) : this.orders
      return arr
    },
    customDate: {
      get() {
        const today = new Date()
        return this.operation.date ? this.operation.date : this.formatDate(today)
      },
      set(val) {
        this.operation.date = val
      }
    }
  },
  watch: {
    searchOrder: {
      handler() {
        this.getOrders()
      },
      deep: true
    },
    searchWorker() {
      this.getWorkers()
    }
  }
}
</script>

<style lang="scss">
.finance-operation {
  .v-form {
    width: 600px;
  }
  .file-input-readonly {
    .v-input__control {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
      }
      .v-input__append-inner {
        position: relative;
        z-index: 3;
      }
    }
  }
}
.operation-dropdown {
  border: 1px solid #E3E6EF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06) !important;
}
</style>
