<template>
  <v-col class="pa-5 pt-10 height-100" ref="company-accounts">
    <resize-observer @notify="resize"/>
    <finance-cards ref="financeCards" v-if="!minimize" :accounts="accounts" />
    <month-swiper
      v-if="!minimize"
      :tableOptions="tableOptions"
      :minimize.sync="minimize"
      :months="months"
      :selectedMonth.sync="selectedMonth"
      :queryOptions.sync="queryOptions" />
    <chart-donut v-if="!minimize"
      :selectedMonth="selectedMonth"
      :queryOptions="queryOptions"
      :months="months" />
    <chart-linear
      v-else-if="selectedMonth"
      ref="chartLinear"
      :months="months"
      :selectedMonth.sync="selectedMonth"
      :minimize.sync="minimize"
      :queryOptions.sync="queryOptions"
      :accounts="accounts" />
    <finance-table
      @getFilteredOperations="getFilteredOperations"
      :headers="headersTable"
      :tableOptions="tableOptions"
      :categories="categories"
      :accounts="accounts"
      :queryOptions.sync="queryOptions"
      :operations="operations" />
    <v-snackbars
        :messages.sync="messages"
        color="red"
        timeout="2000"
        multi-line
        max-width="350"
        left
        rounded="lg"
        light
        elevation="4"
        content-class="snackbar"
      >
      <template v-slot:default="{ message }">
        <strong>{{ message.title }}</strong>
        <p>{{ message.text }}</p>
        <v-row v-if="message.title === 'Error'" class="ma-0 justify-end">
          <v-btn
            class="rounded-lg fz-12 fw-600 text-none border-none"
            outlined
            height="26"
            elevation="0"
            @click="doCopy('Error', message.text)"
          >Copy</v-btn>
        </v-row>
      </template>
    </v-snackbars>
    <div class="preloader" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </v-col>
</template>

<script>
import FinanceCards from '@/components/App/Finance/FinanceCards'
import MonthSwiper from '@/components/App/Finance/MonthSwiper'
import ChartDonut from '@/components/App/Finance/ChartDonut'
import ChartLinear from '@/components/App/Finance/ChartLinear'
import FinanceTable from '@/components/App/Finance/FinanceTable'
import { ResizeObserver } from 'vue-resize'
import VSnackbars from 'v-snackbars'
import 'vue-resize/dist/vue-resize.css'
import gql from 'graphql-tag'

export default {
  components: { FinanceTable, ChartDonut, ChartLinear, MonthSwiper, FinanceCards, 'v-snackbars': VSnackbars, ResizeObserver },
  name: 'Finance',
  data() {
    return {
      minimize: true,
      messages: [],
      headersTable: [
        {
          text: 'Number',
          align: 'center',
          value: 'number',
          filterable: false,
          sortable: false,
          cellClass: 'pointer',
          width: '100px'
        },
        { text: 'Account', value: 'account', filterable: false, sortable: false, cellClass: 'pointer', width: '200px' },
        { text: 'Date', value: 'date', filterable: false, cellClass: 'pointer', width: '100px' },
        { text: 'Category', value: 'category', filterable: false, sortable: false, cellClass: 'pointer', width: '150px' },
        { text: 'Description', value: 'description', filterable: false, cellClass: 'pointer' },
        { text: 'Money', value: 'money', filterable: false, cellClass: 'pointer' },
        { text: 'Overall Balance', value: 'overallBalance', filterable: false, cellClass: 'pointer' },
        { text: 'Attachments', value: 'attachments', sortable: false, cellClass: 'px-0' },
        { text: '', value: 'remove', sortable: false, filterable: false, width: '50px', cellClass: 'pointer' }
      ],
      months: [],
      financePeriod: null,
      accounts: null,
      operations: [],
      tableOptions: {
        totalPages: null,
        totalItems: null,
        totalMoneyOut: null,
        totalMoneyIn: null,
        totalBalance: null
      },
      selectedMonth: null,
      categories: [],
      queryOptions: {
        dateEnd: null,
        dateStart: null,
        orderBy: 'NUMBER_DESC',
        searchNumber: null,
        selectedAccountIds: [],
        selectedCategoryIds: [],
        page: 1,
        pageSize: 7,
        type: null
      },
      loading: false
    }
  },
  methods: {
    resize() {
      this.tableHeight()
      if (this.$refs.financeCards) this.$refs.financeCards.changed()
      if (this.$refs.chartLinear) this.$refs.chartLinear.resize()
    },
    tableHeight () {
      this.queryOptions.pageSize = Math.floor((window.innerHeight - 620) / 48) < 7 ? 7 : Math.floor((window.innerHeight - 620) / 48)
    },
    async getAccounts() {
      this.loading = true
      await this.$apollo.query({
        query: gql`query financeAccounts {
          finance {
            accounts {
              type {
                color
                fontColor
                logo
                name
              }
              name
              lastFourDigits
              id
              expirationDate
              description
              balance
            }
          }
        }`
      }).then((data) => {
        this.loading = false
        this.accounts = data.data.finance.accounts
      }).catch((err) => {
        console.log(err)
      })
    },
    async getFilteredOperations() {
      this.loading = true
      await this.$apollo.query({
        query: gql`query financeFilteredOperations($input: OperationSearchInput!) {
          finance {
            operations(input: $input) {
              financePeriods {
                monthName
                balanceOnEnd
                incomeCategories {
                  value
                  category {
                    forExpense
                    forIncome
                    forTransfer
                    name
                    id
                  }
                }
                expenseCategories {
                  value
                  category {
                    forExpense
                    forIncome
                    forTransfer
                    name
                    id
                  }
                }
                totalIncomeUsd
                totalExpensesUsd
              }
              data {
                amount
                date
                account {
                  id
                  name
                }
                id
                description
                moneyIn
                moneyOut
                moneyDelta
                type
                overallBalance
                transferAccount {
                  name
                  id
                }
                attachments {
                  id
                  name
                  type
                  url
                }
                category {
                  forTransfer
                  required
                  forIncome
                  forExpense
                  id
                  name
                }
                worker {
                  businessName
                  id
                }
                order {
                  client {
                    id
                    name
                  }
                  name
                  id
                }
              }
              totalItems
              totalPages
              totalMoneyOut
              totalMoneyIn
              totalBalance
            }
          }
        }`,
        variables: {
          input: {
            searchId: this.queryOptions.searchNumber,
            accountIds: this.queryOptions.selectedAccountIds,
            categoryIds: this.queryOptions.selectedCategoryIds,
            page: this.queryOptions.page,
            pageSize: this.queryOptions.pageSize,
            start: this.queryOptions.dateStart,
            end: this.queryOptions.dateEnd,
            ordering: this.queryOptions.orderBy,
            type: this.queryOptions.type
          }
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.operations = data.data.finance.operations.data
        this.months = data.data.finance.operations.financePeriods
        // if (!this.queryOptions.dateStart && !this.queryOptions.dateEnd) {
        //   this.months.push({
        //     monthName: 'All',
        //     totalIncomeUsd: data.data.finance.operations.totalMoneyIn,
        //     totalExpensesUsd: data.data.finance.operations.totalMoneyOut,
        //     balanceOnEnd: {
        //       currency: 'USD'
        //     }
        //   })
        // }
        this.tableOptions.totalItems = data.data.finance.operations.totalItems
        this.tableOptions.totalPages = data.data.finance.operations.totalPages
        this.tableOptions.totalMoneyOut = data.data.finance.operations.totalMoneyOut
        this.tableOptions.totalMoneyIn = data.data.finance.operations.totalMoneyIn
        this.tableOptions.totalBalance = data.data.finance.operations.totalBalance
        this.loading = false
      }).catch((err) => {
        console.log(err)
      })
    },
    async getCategories() {
      this.loading = true
      await this.$apollo.query({
        query: gql`query getCategories {
          finance {
            categories {
              forExpense
              forIncome
              forTransfer
              required
              id
              name
            }
          }
        }`
      }).then((data) => {
        this.categories = data.data.finance.categories
        this.loading = false
      }).catch((err) => {
        console.log(err)
      })
    },
    addSnack (item, result, text) {
      this.messages.push({ title: result, text: text })
    },
    doCopy (action, value) {
      const vm = this
      this.$copyText(value).then(function () {
        vm.addSnack(value, 'Success', action + ' text copied')
      }, function (e) {
        console.log(e)
      })
    }
  },
  watch: {
    queryOptions: {
      handler() {
        this.getFilteredOperations()
      },
      deep: true
    },
    months: {
      handler() {
        const vm = this
        setTimeout(function () {
          vm.selectedMonth = vm.months[0]
        }, 0)
      }
    }
  },
  created() {
    this.tableHeight()
  },
  mounted() {
    this.getAccounts()
    this.getFilteredOperations()
    this.getCategories()
  }
}
</script>

<style scoped>

</style>
