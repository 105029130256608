<template>
  <v-col class="pa-0">
    <v-row class="ma-0">
      <v-btn-toggle
          class="rounded-lg height-30 mb-5 box-shadow interval black--text hidden"
          style="padding-left: 2px; padding-right: 2px"
          :class="{'mr-3': $vuetify.breakpoint.smAndUp }"
          background-color="#F2F4F5"
          v-model="interval"
          borderless
          dense>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            @click="getPrevious"
            height="26">
            Previous Month
          </v-btn>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            @click="getCurrent"
            height="26">
            Сurrent Month
          </v-btn>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            @click="getAllPeriod"
            height="26">
            All period
          </v-btn>
        </v-btn-toggle>
      <v-btn
          color="#010820"
          class="rounded-lg mr-5 mb-5 fz-13 fw-600 text-none"
          :class="{'mt-4': $vuetify.breakpoint.xsOnly}"
          height="30"
          @click="showDatepicker"
          outlined>
          {{ queryOptions.dateStart && queryOptions.dateEnd ? 'Period: ' + queryOptions.dateStart + ' - ' + queryOptions.dateEnd : 'Specify period' }}
          <div @click.stop>
            <v-icon size="16" v-if="queryOptions.dateStart && queryOptions.dateEnd" class="ml-2" @click="getAllPeriod">mdi-close</v-icon>
          </div>
        </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="#F6F8F9" class="btn-shrink text-none fz-14 fw-600 text-black rounded-lg" height="30" elevation="0" @click="shrink">Minimize
        <v-icon class="ml-2" size="14">$shrink</v-icon>
      </v-btn>
    </v-row>
    <v-row class="ma-0 mb-5">
      <v-sheet
        class="mx-auto month-swiper"
      >
        <v-slide-group
          show-arrows
          v-model="model"
          prev-icon="$swiperPrev"
          next-icon="$swiperNext"
          @click:next="changed"
          @click:prev="changed"
        >
          <v-slide-item
            v-for="(month, index) in monthReverse"
            :key="month.monthName + index"
            v-slot="{ active }"
          >
            <v-btn
              class="mx-2 text-none fz-14 font-weight-medium"
              :input-value="active"
              active-class="white--text"
              height="40"
              depressed
              rounded
              @click="changeMonth(index)"
            >
              {{ month.monthName + ', ' }}
              {{ month.balanceOnEnd && (month.balanceOnEnd.symbol !== '₽') ? month.balanceOnEnd.symbol : ''}}{{ (queryOptions.type === 'INCOME' ? +month.totalIncomeUsd.amount : queryOptions.type === 'EXPENSE' ? +month.totalExpensesUsd.amount : month.totalIncomeUsd.amount - month.totalExpensesUsd.amount) | numberFormat }}{{ month.balanceOnEnd && (month.balanceOnEnd.symbol === '₽') ? month.balanceOnEnd.symbol : ''}}
            </v-btn>
          </v-slide-item>
<!--          <v-slide-item-->
<!--            v-if="interval === 2"-->
<!--            v-slot="{ active }">-->
<!--            <v-btn-->
<!--              class="mx-2 text-none fz-14 font-weight-medium"-->
<!--              :input-value="active"-->
<!--              active-class="white&#45;&#45;text"-->
<!--              height="40"-->
<!--              depressed-->
<!--              rounded-->
<!--            >-->
<!--              {{ 'All, ' }}-->
<!--              {{ (queryOptions.type === 'INCOME' ? +tableOptions.totalMoneyIn.amount : queryOptions.type === 'EXPENSE' ? +tableOptions.totalMoneyOut.amount : +tableOptions.totalBalance.amount) | numberFormat }}-->
<!--               $-->
<!--            </v-btn>-->
<!--          </v-slide-item>-->
        </v-slide-group>
      </v-sheet>
    </v-row>
    <vc-date-picker
      class="inline-block h-full"
      locale="en"
      :class="{'date-picker-orders': $vuetify.breakpoint.mdAndUp, 'date-picker-mobile': $vuetify.breakpoint.smAndDown}"
      :popover="{ visibility: 'click' }"
      :columns="$vuetify.breakpoint.mdAndUp ? 2 : 1"
      mode="range"
      is-range
      @popoverWillShow="overlay = true"
      @popoverDidHide="overlay = false"
      :masks="{input: 'DD.MM.YYYY', weekdays: 'WW'}"
      v-model="date">
      <template v-slot="">
        <button
          ref="datepickerShow"
          style="display: none"
        ></button>
      </template>
    </vc-date-picker>
  </v-col>
</template>

<script>
import numberFormat from '@/helpers/numberFormat'
import { Locale } from 'v-calendar'
const locale = new Locale()

export default {
  name: 'MonthSwiper',
  props: ['minimize', 'months', 'selectedMonth', 'queryOptions', 'tableOptions'],
  filters: {
    numberFormat
  },
  data() {
    return {
      interval: 2,
      blur: {
        left: false,
        right: true
      }
    }
  },
  computed: {
    monthReverse() {
      const arr = this.months.slice()
      return arr.reverse()
    },
    date: {
      get () {
        const object = {
          end: this.queryOptions.dateEnd ? locale.parse(this.queryOptions.dateEnd, 'DD.MM.YYYY') : new Date(),
          start: this.queryOptions.dateStart ? locale.parse(this.queryOptions.dateStart, 'DD.MM.YYYY') : new Date()
        }
        return object
      },
      set (val) {
        this.interval = -1
        this.queryOptions.dateStart = val.start ? locale.format(val.start, 'DD.MM.YYYY') : ''
        this.queryOptions.dateEnd = val.end ? locale.format(val.end, 'DD.MM.YYYY') : ''
      }
    },
    model: {
      get () {
        return this.monthReverse.indexOf(this.selectedMonth)
      },
      set (val) {
        this.$emit('update:selectedMonth', this.monthReverse[val])
      }
    }
  },
  methods: {
    changeMonth(index) {
      this.model = index
      // this.$emit('update:selectedMonth', this.monthReverse[index])
    },
    getCurrent () {
      const start = new Date()
      const end = new Date()
      start.setDate(1)
      end.setMonth(end.getMonth() + 1)
      end.setDate(0)
      this.queryOptions.dateStart = locale.format(start, 'DD.MM.YYYY')
      this.queryOptions.dateEnd = locale.format(end, 'DD.MM.YYYY')
      const vm = this
      setTimeout(function () {
        vm.interval = 1
      }, 0)
    },
    getPrevious () {
      const start = new Date()
      const end = new Date()
      start.setDate(1)
      start.setMonth(end.getMonth() - 1)
      end.setDate(0)
      this.queryOptions.dateStart = locale.format(start, 'DD.MM.YYYY')
      this.queryOptions.dateEnd = locale.format(end, 'DD.MM.YYYY')
      const vm = this
      setTimeout(function () {
        vm.interval = 0
      }, 0)
    },
    getAllPeriod () {
      this.queryOptions.dateStart = null
      this.queryOptions.dateEnd = null
      const vm = this
      setTimeout(function () {
        vm.interval = 2
      }, 0)
    },
    showDatepicker () {
      const vm = this
      setTimeout(function () {
        vm.$refs.datepickerShow.click()
      }, 0)
    },
    shrink() {
      this.$emit('update:minimize', true)
    },
    changed () {
      const accounts = document.querySelectorAll('.month-swiper')[0].childNodes[0]
      const vm = this
      setTimeout(function () {
        vm.blur.left = !accounts.querySelector('.v-slide-group__prev--disabled')
        vm.blur.right = !accounts.querySelector('.v-slide-group__next--disabled')
      })
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss">

.month-swiper {
  width: 100%;
  .v-slide-group__next, .v-slide-group__prev {
    z-index: 5;
  }
  .v-slide-group__wrapper {
    position: relative;
    //&:before, &:after {
    //  content: '';
    //  position: absolute;
    //  width: 60px;
    //  height: 100%;
    //  top: 0;
    //  z-index: 1;
    //}
    //&:before {
    //  left: 0;
    //  background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    //}
    //&:after {
    //  right: 0;
    //  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    //}
  }
  //.v-slide-group__content {
  //  padding-right: 30%;
  //}
  .v-btn {
    background-color: #EEF2F7;
    color: #5A6176;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .v-btn--active {
    background-color: #010820 !important;
  }
  .rightBlur:after {
    content: '';
    position: absolute;
    width: 60px;
    right: 40px;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    backdrop-filter: blur(1px);
  }
  .leftBlur:before {
    content: '';
    position: absolute;
    width: 60px;
    left: 40px;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    backdrop-filter: blur(2px);
  }
}
.btn-shrink {
  background: #F6F8F9;
  border-radius: 8px !important;
}
</style>
