<template>
  <svg class="chart-donut mr-20" :class="chartName"></svg>
</template>

<script>
import * as d3 from 'd3'

export default {
  name: 'Donut',
  props: ['chartName', 'data', 'tooltip', 'tooltiptype', 'colors'],
  methods: {
    createChart () {
      const vm = this
      const pie = d3.pie()
        .padAngle(0.0)
        .sort(null) // (a, b) => b.value - a.value
        .value(d => d.value.amount)
      const arc = d3.arc().innerRadius(90 * 0.90).outerRadius(90 - 1)
      const arcHover = d3.arc().innerRadius(90 * 0.85).outerRadius(90 + 3)
      const colors = d3.scaleOrdinal()
        .domain(this.data.map((d, i) => i))
        .range(d3.quantize(t => d3.interpolateSpectral(t * 0.99 + 0.1), this.data.length).reverse())
      this.$emit('update:colors', colors)
      const arcs = pie(this.data)
      const svg = d3.select('.' + this.chartName)
      svg.selectAll('g').remove()
      const g = svg.append('g')
        .attr('transform', 'translate(100 100)')
      const path = g.selectAll('path')
        .data(arcs)
        .join('path')
        .attr('fill', (d, i) => colors(i))
        .attr('d', arc)
      path.on('mouseover', function (event, d) {
        g.selectAll('path')
          .attr('style', 'opacity: .6')
        d3.select(this)
          .attr('style', 'opacity: 1')
          .attr('d', arcHover)
        vm.tooltip[vm.tooltiptype] = {
          value: d.data.value.amount,
          name: d.data.category.name
        }
      })
      path.on('mouseleave', function (event, d) {
        g.selectAll('path')
          .attr('style', 'opacity: 1')
        d3.select(this)
          .attr('d', arc)
        vm.tooltip[vm.tooltiptype] = null
      })
    }
  },
  mounted() {
    this.createChart()
  }
}
</script>

<style lang="scss">

</style>
